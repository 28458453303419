import React, { FC } from 'react';
import { makeStyles, Typography, TypographyProps } from '@material-ui/core';
import { MONTBLANC_FONT_FAMILY } from 'components/Layout/theme';

const useStyles = makeStyles((theme) => ({
  default: {
    margin: '2rem 0 1.25rem 0',
    fontSize: '2.125rem',
    letterSpacing: '-0.02em',
    fontFamily: MONTBLANC_FONT_FAMILY,
  },
  superHero: {
    margin: '2rem 0 1.25rem 0',
    fontSize: '6rem',
    letterSpacing: '-0.02em',
    fontFamily: MONTBLANC_FONT_FAMILY,
  },
  hero: {
    margin: '2rem 0 1.25rem 0',
    fontFamily: MONTBLANC_FONT_FAMILY,
    letterSpacing: '-0.02em',
    fontSize: '2.5rem',
    lineHeight: '2.8rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '4.25rem',
      lineHeight: '4.5rem',
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: 'clamp(2.5rem, -0.875rem + 8.333vw, 4.25rem)',
      lineHeight: 'clamp(2.8rem, -0.875rem + 8.333vw, 4.5rem)',
    },
  },
  small: {
    margin: '1.5rem 0 1rem 0',
    fontSize: '1.75rem',
    letterSpacing: '-0.02em',
    fontFamily: MONTBLANC_FONT_FAMILY,
  },
}));

export interface H1Props extends Omit<TypographyProps<'h1'>, 'variant'> {
  variant?: 'super-hero' | 'hero' | 'small' | 'default';
}

/**
 * @deprecated Migrate to H1 Component
 */
export const H1_Legacy: FC<H1Props> = (props) => {
  const classes = useStyles();
  const { children, className, variant = 'default', ...other } = props;

  const map = new Map(
    Object.entries({
      'super-hero': classes.superHero,
      hero: classes.hero,
      default: classes.default,
      small: classes.small,
    })
  );

  return (
    <Typography {...other} variant="h1" className={map.get(variant) + (className ? ' ' + className : '')}>
      {children}
    </Typography>
  );
};
