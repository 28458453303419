import React, { forwardRef, PropsWithChildren } from 'react';
import Grid from '@material-ui/core/Grid';
import { Container, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export type ResponsiveGridProps = {
  className?: string;
  gridContainerClassName?: string;
  noGutters?: boolean;
};

export const ResponsiveGrid = forwardRef<HTMLDivElement, PropsWithChildren<ResponsiveGridProps>>(
  function ResponsiveGrid({ children, className, gridContainerClassName, noGutters }, ref) {
    const theme = useTheme();
    const classes = useStyles();
    const md = useMediaQuery(theme.breakpoints.down('md'));

    return (
      <div className={cx(className, classes.root)}>
        {noGutters ? (
          <Grid
            ref={ref}
            className={cx(gridContainerClassName)}
            justifyContent="center"
            alignItems="flex-start"
            container
            spacing={md ? 2 : 3}
          >
            {children}
          </Grid>
        ) : (
          <Container maxWidth="lg">
            <Grid
              ref={ref}
              className={cx(gridContainerClassName)}
              justifyContent="center"
              alignItems="flex-start"
              container
              spacing={md ? 2 : 3}
            >
              {children}
            </Grid>
          </Container>
        )}
      </div>
    );
  }
);
