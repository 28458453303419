export const images = {};

export const pages = {
  NEW_APPOINTMENT: 'https://calendly.com/johniecrew/discuss-home-loan-options',
};

export const apis = {
  APPOINTMENTS: '/api/appointments',
  APPOINTMENTS_UNAVAILABLE: '/api/appointments/unavailable',
  APPOINTMENTS_PUBLIC: '/api/appointments/public',
  APPOINTMENTS_LATEST: '/api/appointments/latest',
};
