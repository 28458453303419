import React, { FC } from 'react';
import { makeStyles, Typography, TypographyProps } from '@material-ui/core';
import { MONTBLANC_FONT_FAMILY } from 'components/Layout/theme';

const useStyles = makeStyles((theme) => ({
  default: { margin: '1.5rem 0 1rem 0', fontSize: '1.75rem', fontFamily: MONTBLANC_FONT_FAMILY },
  hero: {
    margin: '2rem 0 1.25rem 0',
    fontFamily: MONTBLANC_FONT_FAMILY,
    letterSpacing: '-0.02em',
    fontSize: '1.8rem',
    lineHeight: '2rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '3.25rem',
      lineHeight: '3.5rem',
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: 'clamp(1.8rem, -0.875rem + 8.333vw, 3.25rem)',
      lineHeight: 'clamp(2rem, -0.875rem + 8.333vw, 3.5rem)',
    },
  },
}));

interface H2Props extends Omit<TypographyProps<'h2'>, 'variant'> {
  variant?: 'hero' | 'default';
}

/**
 * @deprecated Migrate to H2 Component
 */
export const H2_Legacy: FC<H2Props> = (props) => {
  const classes = useStyles();
  const { children, className, variant = 'default', ...other } = props;

  const map = new Map(
    Object.entries({
      hero: classes.hero,
      default: classes.default,
    })
  );

  return (
    <Typography {...other} variant="h2" className={map.get(variant) + (className ? ' ' + className : '')}>
      {children}
    </Typography>
  );
};
