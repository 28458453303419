import React, { FC } from 'react';
import { makeStyles, Typography, TypographyProps } from '@material-ui/core';
import { ROBOTO_FONT_FAMILY } from 'components/Layout/theme';

const useStyles = makeStyles({
  default: { fontSize: '1.1875rem', fontWeight: 300, fontFamily: ROBOTO_FONT_FAMILY },
  body1: { fontSize: '1.1875rem', fontWeight: 300, fontFamily: ROBOTO_FONT_FAMILY },
  bodyBold: { fontSize: '1.1875rem', fontWeight: 500, fontFamily: ROBOTO_FONT_FAMILY },
  body2: { fontSize: '1rem', fontWeight: 400, fontFamily: ROBOTO_FONT_FAMILY },
  body3: { fontSize: '0.875rem', fontWeight: 400, fontFamily: ROBOTO_FONT_FAMILY },
  caption1: { fontSize: '0.8125rem', fontWeight: 500, fontFamily: ROBOTO_FONT_FAMILY },
  caption2: { fontSize: '0.75rem', fontWeight: 400, fontFamily: ROBOTO_FONT_FAMILY },
});

export interface BodyProps extends Omit<TypographyProps<'span'>, 'variant' | 'component'> {
  variant?: 'default' | 'body1' | 'body2' | 'body3' | 'caption1' | 'caption2' | 'bodyBold';
}

export interface PProps extends Omit<TypographyProps<'span'>, 'variant' | 'component'> {
  variant?: 'default' | 'body1' | 'body2' | 'body3' | 'caption1' | 'caption2' | 'bodyBold';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Body: FC<any> = (props, component: string) => {
  const classes = useStyles();
  const { children, className, variant = 'default', ...other } = props;

  const map = new Map(
    Object.entries({
      default: classes.default,
      body1: classes.body1,
      body2: classes.body2,
      body3: classes.body3,
      bodyBold: classes.bodyBold,
      caption1: classes.caption1,
      caption2: classes.caption2,
    })
  );

  const newClassName = map.get(variant) + (className ? ' ' + className : '');

  return (
    <Typography {...other} component={component} className={newClassName}>
      {children}
    </Typography>
  );
};

/**
 * @deprecated Migrate to P Component
 */
export const P_Legacy: FC<PProps> = (props) => {
  return Body(props, 'p');
};

/**
 * @deprecated Migrate to Span Component
 */
export const Span_Legacy: FC<BodyProps> = (props) => {
  return Body(props, 'span');
};
