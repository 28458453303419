import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useRef } from 'react';
import cx from 'classnames';
import { Spinner } from './spinner';
import { gtmTagPropsToDataset } from 'utils/gtm-tag-utils';

type AsyncButtonProps = {
  isExecuting?: boolean;
} & ButtonProps &
  Required<GtmTagProps>;

const useStyles = makeStyles(() => ({
  spinner: {
    position: 'absolute',
    top: 'calc(50% - 15px)',
    right: 'calc(50% - 15px)',
  },
  savingButton: {
    pointerEvents: 'none',
    '& > span': {
      position: 'relative',
    },
  },
  hiddenInner: {
    opacity: '0',
  },
}));

export const AsyncButton: FC<AsyncButtonProps> = ({
  className,
  children,
  disabled,
  isExecuting,
  gtmEventAction,
  gtmEventCategory,
  ...other
}) => {
  const classes = useStyles();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current && document.activeElement === buttonRef.current && isExecuting) {
      buttonRef.current.blur();
    }
  }, [isExecuting]);

  const gtmProps = gtmTagPropsToDataset({ gtmEventCategory, gtmEventAction });

  return isExecuting ? (
    <Button tabIndex={-1} ref={buttonRef} className={cx(classes.savingButton, className)} {...other} {...gtmProps}>
      <div className={classes.hiddenInner}>{children}</div>
      {other.variant === 'outlined' ? (
        <Spinner className={classes.spinner} />
      ) : (
        <Spinner isWhite className={classes.spinner} />
      )}
    </Button>
  ) : (
    <Button className={className} disabled={disabled} {...other} {...gtmProps}>
      {children}
    </Button>
  );
};
