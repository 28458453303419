/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import MuiLink, { LinkProps as MuiLinkProps } from '@material-ui/core/Link';
import { TypographyVariant, Span } from './typography';
import { gtmTagPropsToDataset } from 'utils/gtm-tag-utils';

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<NextLinkProps, 'href' | 'as'> {
  to: NextLinkProps['href'];
  linkAs?: NextLinkProps['as'];
  href?: NextLinkProps['href'];
}

const NextLinkComposed = React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>(function NextLinkComposed(
  props,
  ref
) {
  const { to, linkAs, href, replace, scroll, passHref, shallow, prefetch, locale, ...other } = props;

  return (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      locale={locale}
    >
      <a ref={ref} {...other} />
    </NextLink>
  );
});

export type LinkProps = {
  activeClassName?: string;
  as?: NextLinkProps['as'];
  href?: NextLinkProps['href'];
  noLinkStyle?: boolean;
  variant?: TypographyVariant;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href' | 'variant'> &
  Omit<MuiLinkProps, 'href' | 'variant'> &
  GtmTagProps;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, ref) {
  const {
    activeClassName = 'active',
    as: linkAs,
    className: classNameProps,
    href = '',
    noLinkStyle,
    role, // Link don't have roles.
    children,
    //remove default button1 variant (should inherit parent if not specified)
    variant,
    gtmEventCategory,
    gtmEventAction,
    ...other
  } = props;

  const router = useRouter();
  const pathname = typeof href === 'string' ? href : href?.pathname || '';
  const className = cx(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName,
  });

  const gtmAttributes = gtmTagPropsToDataset({ gtmEventCategory, gtmEventAction });
  const isExternal = typeof href === 'string' && (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0);

  if (isExternal) {
    if (noLinkStyle) {
      return (
        <a className={className} href={href as string} ref={ref} {...other} {...gtmAttributes}>
          {variant ? <Span variant={variant}>{children}</Span> : <>{children}</>}
        </a>
      );
    }

    return (
      <MuiLink className={className} href={href as string} ref={ref} {...other} {...gtmAttributes}>
        {variant ? <Span variant={variant}>{children}</Span> : <>{children}</>}
      </MuiLink>
    );
  }

  if (noLinkStyle) {
    return (
      <NextLinkComposed className={className} ref={ref} to={href} {...other} {...gtmAttributes}>
        {variant ? <Span variant={variant}>{children}</Span> : <>{children}</>}
      </NextLinkComposed>
    );
  }

  return (
    <MuiLink
      component={NextLinkComposed}
      linkAs={linkAs}
      className={className}
      ref={ref}
      to={href}
      {...other}
      {...gtmAttributes}
    >
      {variant ? <Span variant={variant}>{children}</Span> : <>{children}</>}
    </MuiLink>
  );
});
